// create flexslider slider on offers block
jQuery(document).ready(function($){
    $('.offers .offers-slider').slick({
        fade: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><span class="screen-reader-text">Previous</span></button>',
        nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><span class="screen-reader-text">Next</span></button>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    // ACCOMMODATIONS CATEGORY TABS
		//Set inital state
		$('.offers .offer-posts .tab-contents').first().addClass('active');
		$('.offers .offer-posts .tab-controls button').first().addClass('active');
		$('.offers .offer-posts .child-tab-contents').first().addClass("active");
		//Clicks on parent category tabs
		$('.offers .offer-posts .tab-controls button').click(function(){
			var tab_id = $(this).attr('data-category');
			$('.offers .offer-posts .tab-controls button').removeClass('active');
			$('.offers .offer-posts .tab-contents').removeClass('active');
			$(this).addClass('active');
			$("#"+tab_id).addClass('active');
			$("#"+tab_id+' .child-tab-contents').removeClass('active');
			$("#"+tab_id+' .child-tab-contents').first().addClass('active');
		});

        //if there's a hash in the url, open the tab
        var hash = window.location.hash;
        if (hash) {
            $('.offers .offer-posts .tab-controls button').removeClass('active');
            $('.offers .offer-posts .tab-contents').removeClass('active');
            $(hash).addClass('active');
            $(hash).find('.child-tab-contents').removeClass('active');
            $(hash).find('.child-tab-contents').first().addClass('active');
            // add active to the tab
            $('button[data-category="'+hash.substring(1)+'"]').addClass('active');
        }
});
